@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.dd-custom .search-box-container {
  background: #ffffff;
}
.dd-custom .dd-search-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dd-custom .dd-trigger {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
}
.dd-custom .dd-trigger:not(.is-open) {
  border: 1px solid #EDEDED;
}
.dd-custom .dd-trigger.is-open {
  border-top: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
}
.dd-custom .dd-trigger input {
  padding: 10px;
  border: none;
  flex-grow: 1;
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger input:focus {
  outline: none;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger input {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text {
  flex-grow: 1;
  color: #9e9e9e;
}
.dd-custom .dd-trigger .label-text:not(.small) {
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger .label-text:not(.small) {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text.small {
  padding: 10px;
}
.dd-custom .dd-trigger .ddt-icon {
  color: #9e9e9e;
  padding: 10px 10px 10px 0px;
}
.dd-custom .dd-trigger.selected:not(.format-selected) .label-text {
  color: #2E2D2E;
}
.dd-custom .dd-trigger.format-selected {
  background-color: #DE192B;
}
.dd-custom .dd-trigger.format-selected .label-text {
  color: #ffffff;
}
.dd-custom .dd-trigger.format-selected .ddt-icon {
  color: #ffffff;
}
.dd-custom .dd-trigger .add-button {
  margin-left: 10px;
}
.dd-custom .menu.transition.visible {
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0px;
  box-shadow: none;
}

.simple-list-item {
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 8px 10px;
  color: #2E2D2E;
  cursor: pointer;
}
.simple-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.simple-list-item.selected {
  color: #ffffff;
  background: #DE192B;
}

.valid-icon {
  margin-right: 10px;
}

.warning-icon {
  margin-right: 10px;
  color: #F6B93B;
}