@import '../../../styles/variables';

$x: 45px;
$y: 50px;

.progress-bar{
    .step{
        width: calc(2*#{$x} + #{$y});
        position: relative;
        .description{
            margin-top: 3px;
            font-size: 16px;
            line-height: 25px;
        }
        .line{
            position: absolute;
            top: 24px;
            left: calc(#{$x} + #{$y});
            width: calc(2*#{$x});
            border: 0.5px solid $neutral-4-color;
        }   
        .progress-line{
            position: absolute;
            top: 23px;
            left: calc(#{$x} + #{$y});
            
        }
    }
    .oval{
        width: $y;
        height: $y;
        border-radius: 100px;
        border: $default-border;
        font-size: 16px;
        font-weight: bold;
        &.busy{
            background-color: $success-color;
            border: none;
            color: $white;
        }
        &.can-click{
            &:hover{
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}

.mobile-progress-bar-container{
    height: 10px;
    width: 100%;
    background-color: $neutral-4-color;
    .left-progress{
        background-color: $success-color;
        height: 100%;
    }
}