@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.header-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ui.modal.default-modal .content .blank-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blank-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container {
    margin-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blank-container .blank-container-buttons {
  margin-top: 40px;
}
.blank-container .blank-container-buttons .previous-button {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .blank-container .blank-container-buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid #EDEDED;
  }
  .blank-container .blank-container-buttons .df-row-ac {
    width: 100%;
    justify-content: space-between;
  }
  .blank-container .blank-container-buttons .df-row-ac .primary-button {
    width: auto;
  }
}

.blank-mobile-footer {
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  border-top: 1px solid #EDEDED;
}

.calculated-container {
  font-size: 16px;
  line-height: 25px;
}
.calculated-container .single-price-calc {
  margin-right: 20px;
}
.calculated-container .c-summ {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.calculated-container .add-cost-desc {
  margin-left: 20px;
}
.calculated-container .gen-cond-container {
  margin-top: 30px;
}
.calculated-container .gen-cond-container .gen-cond-icon {
  font-size: 20px;
  margin-top: 4px;
  color: #EB2F06;
  margin-right: 10px;
}
.calculated-container .gen-cond-container .gen-cond {
  font-size: 12px;
  line-height: 18px;
}
.calculated-container .calc-item {
  margin-top: 10px;
}
.calculated-container .total {
  font-size: 18px;
  font-weight: 600;
}
.calculated-container .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.calculated-container .label:not(.without-margin) {
  margin-top: 20px;
}
.calculated-container .info {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .calculated-container .info {
    font-size: 14px;
    line-height: 21px;
  }
}
.calculated-container .calc-stops-view {
  margin-top: 20px;
}