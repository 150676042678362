@import '../../../styles/variables';

.text-area-field{
    width: 100%;
    resize: none;
    height: 150px;
    padding: 14.5px 8.5px;
    font-size: 18px;
    line-height: 24px;
    color: $neutral-1-color;
    border: $default-border;
}