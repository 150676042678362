@import '../../../styles/variables';

.search-box{
    input{
        border: none;
        flex-grow: 1;
        &:focus{
            outline: none;
        }
    }
    &.large {
        input {
            font-size: 16px;
            line-height: 16px;
        }
    }
    &:not(.large){
        input {
            font-size: 12px;
            line-height: 12px;
        }
    }
    .search-button{
        color: $neutral-3-color;
        margin-right: 10px;
        margin-left: 10px;
    }
    .clear-button {
        color: $neutral-3-color;
        margin-right: 10px;
    }
}