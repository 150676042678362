@import '../../styles/variables';

.header-text{
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 30px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
}
.ui.modal.default-modal{
    .content{
        .blank-container{
            @media screen and (max-width: 767px){
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}

.blank-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
        margin-bottom: 120px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .blank-container-buttons{
        margin-top: 40px;
        .previous-button{
            margin-right: 15px;
        }
        @media screen and (max-width: 767px) {
            position: fixed;
            bottom: 0px;
            left: 0px;
            background: $white;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 20px;
            padding-bottom: 40px;
            width: 100%;
            border-top: 1px solid $neutral-5-color; 
            .df-row-ac{
                width: 100%;
                justify-content: space-between;
                .primary-button{
                    width: auto;
                }
            }
        }
    }
}

.blank-mobile-footer{
    position: sticky;
    bottom: 0px;
    background: $white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid $neutral-5-color; 
}