@import './variables';

.df-row {
    display: flex;
    flex-direction: row;
}

.df-row-ac {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.df-col-if-mobile {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.df-col-ac-if-mobile {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.hide-if-mobile {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.df-col-reverse-if-mobile {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.df-row-ab {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.df-row-afs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.df-row-afs-left {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
}

.df-row-afs-right {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: flex-end;
}

.df-col-ac {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.df-col-ab {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.df-col {
    display: flex;
    flex-direction: column;
}

.jc-sb {
    justify-content: space-between;
}

.jc-c {
    justify-content: center;
}

.jc-e {
    justify-content: flex-end;
}

.jc-se {
    justify-content: space-evenly;
}

.sticky-at-top {
    position: sticky;
    top: 0;
}

.sticky-at-bottom {
    position: sticky;
    bottom: 0;
}

.stretch-ver {
    height: 100%;
}

.stretch-hor {
    width: 100%;
}

.tac {
    text-align: center;
}

.fg1 {
    flex-grow: 1;
}

.font-bold {
    font-weight: 600;
}

.font-error {
    color: $error-color;
}

.font-success {
    color: $success-color;
}

.pos-rel {
    position: relative;
}

.ml-10px {
    margin-left: 10px;
}

.mr-5px {
    margin-right: 5px;
}

.mr-20px {
    margin-right: 20px;
}

.z-index-1 {
    z-index: 1;
}

.overflow-auto {
    overflow: auto;
}

.padding-10px {
    padding: 10px;
}

.jc-c-if-mobile {
    @media screen and (max-width: 767px) {
        justify-content: center;
    }
}