@import '../../../styles/variables';

.dd-container {
    .dd-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    .dd-label-file-number {
        font-size: 16px;
        line-height: 25px;
        color: $neutral-2-color;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .dd-file-number {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        margin-bottom: 14px;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 25px;
        }
    }

    .cancel-button {
        margin-right: 20px;

        @media screen and (max-width: 767px) {
            margin-right: inherit;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }
}