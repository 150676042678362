@import '../../../styles/variables';

.header-container{
    padding: 40px;
    width: 100%;
    position: sticky;
    top: 0px;
    background: $white;
    z-index: 1;
    @media screen and (max-width: 767px) {
        padding: 0px;
    }
    .left{
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
    }
    .right{
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        justify-content: flex-end;
    }
    .cancel-button{
        margin-left: 10px;
    }
    .cancel-button, .language-button {
        @media screen and (max-width: 767px) {
            color: $neutral-2-color;
        }
    }
    .mobile-cancel-button{
        color: $neutral-2-color;
    }
    .saved-as-draft{
        padding: 7px 15px 7px 15px;
        border-radius: 100px;
        color: white;
        background-color: $success-color;
        margin-right: 10px;
        .status-text{
            margin-left: 5px;
            line-height: 21px;
        }
    }
}

.sl-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}