@import '../../../styles/variables';

.max-stops-container {
    margin-top: 30px;

    .max-stops-icon {
        font-size: 20px;
        margin-top: 4px;
        color: $neutral-3-color;
        margin-right: 10px;
    }

    .max-stops {
        font-size: 12px;
        line-height: 18px;
    }
}