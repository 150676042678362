.time-list-items-container{
    flex-wrap: wrap;
    padding: 10px;
    .time-list-item{
        // margin: 1px;
        width: 25%;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        cursor: pointer;
        padding: 1px;
        &.selected{
            .time-list-item-inner{
                background-color: #DE192B;
                border-radius: 2px;
                color: #FFFFFF;
            }
        }
        &:hover{
            .time-list-item-inner{
                background-color: #DE192B;
                border-radius: 2px;
                opacity: 0.8;
                color: #FFFFFF;
            }
        }
    }
}
