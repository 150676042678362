@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
.ui.card {
  padding: 15px;
  margin: 0;
  width: auto;
  box-shadow: 2px 2px 8px 0 rgba(151, 151, 151, 0.6);
  background-color: #ffffff;
  border-radius: 2px;
  border: none;
}
@media screen and (max-width: 767px) {
  .ui.card {
    padding: 10px;
  }
}

.ui.modal {
  position: relative;
}
.ui.modal .content {
  height: 100%;
  padding: 28px 50px 31px 50px;
}

.ui.table {
  margin-top: 0;
  border: none;
  border-radius: 0;
}

.ui.table tfoot th {
  background: white;
}

.ui.table td.no-padding {
  padding: 0px;
}

.ui.divider.no-margin {
  margin: 0px;
}

.ui.table thead th {
  font-weight: 500;
  background: #EDEDED !important;
  border-left: none !important;
}
.ui.table thead th:hover {
  background: #EDEDED;
}
.ui.table thead th.no-sort {
  cursor: default;
}

.ui.scrolling.dropdown .menu.dd-menu {
  min-width: 250px !important;
}
.ui.scrolling.dropdown .menu.no-max-height {
  max-height: none !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65);
}

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
  box-shadow: 2px 2px 8px 0 #9e9e9e !important;
  background-color: #9e9e9e !important;
  border-radius: 5px !important;
  padding: 28px 20px !important;
}

.Toastify__toast--default {
  background-color: #9e9e9e !important;
}

.Toastify__toast--success {
  background-color: #4EB064 !important;
}

.Toastify__toast--warning {
  background-color: #F6B93B !important;
}

.Toastify__toast--error {
  background-color: #EB2F06 !important;
}

@media only screen and (max-width: 767px) {
  .ui.modal {
    width: 100%;
    margin: 0;
  }
  .ui.scrolling.dropdown .menu {
    width: 100% !important;
    max-height: inherit !important;
  }
}
@media screen and (min-width: 768px) {
  .ui.modal .close.icon {
    display: none;
  }
}

.df-row {
  display: flex;
  flex-direction: row;
}

.df-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .df-col-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .df-col-ac-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .hide-if-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .df-col-reverse-if-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.df-row-ab {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.df-row-afs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.df-row-afs-left {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}

.df-row-afs-right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.df-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-col-ab {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.jc-se {
  justify-content: space-evenly;
}

.sticky-at-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-at-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.stretch-ver {
  height: 100%;
}

.stretch-hor {
  width: 100%;
}

.tac {
  text-align: center;
}

.fg1 {
  flex-grow: 1;
}

.font-bold {
  font-weight: 600;
}

.font-error {
  color: #EB2F06;
}

.font-success {
  color: #4EB064;
}

.pos-rel {
  position: relative;
}

.ml-10px {
  margin-left: 10px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-20px {
  margin-right: 20px;
}

.z-index-1 {
  z-index: 1;
}

.overflow-auto {
  overflow: auto;
}

.padding-10px {
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .jc-c-if-mobile {
    justify-content: center;
  }
}

html {
  display: flex;
  width: 100%;
}
html body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

html, body, #root {
  height: 100%;
  overflow: auto;
}

input, textarea, select, button {
  font-family: "Poppins", sans-serif;
}
.toast-notification {
  font-family: "Poppins", sans-serif;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.default-modal {
  max-width: 600px;
  min-height: 400px;
}

.text-link-button {
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  font-size: 16px;
  line-height: 25px;
}
.text-link-button:hover {
  opacity: 0.8;
}
.text-link-button.tlb-gray {
  color: #9e9e9e;
}
.text-link-button.tlb-underline {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .text-link-button {
    font-size: 14px;
    line-height: 21px;
  }
}
.text-link-button.tlb-small {
  font-size: 14px;
  line-height: 21px;
}
.text-link-button.tlb-extra-small {
  font-size: 12px;
  line-height: 16px;
}
.text-link-button:focus {
  outline: none;
}
.primary-button {
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
  text-align: center;
  text-decoration: none;
  border: 1px solid #DE192B;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  white-space: nowrap;
}
.primary-button .loader-wrapper {
  position: absolute;
  width: 100%;
}
.primary-button .loader-children {
  visibility: hidden;
}
.primary-button.stretch {
  width: 100%;
}
.primary-button:not(.small) {
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .primary-button:not(.small) {
    font-size: 16px;
    line-height: 25px;
  }
}
.primary-button.small {
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  font-weight: 400;
}
.primary-button:not(.outline) {
  background-color: #DE192B;
  color: #ffffff;
}
.primary-button.outline {
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
  background: transparent;
}
.primary-button:hover:not(.drop-down disabled) {
  opacity: 0.7;
}
.primary-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.primary-button:focus {
  outline: none;
}
.primary-button.rectangle-padding {
  padding: 10px 30px;
}
.confirmation-modal .confirmation-modal-inner {
  flex-grow: 1;
}
.confirmation-modal .mc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.confirmation-modal .mc-description {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-description {
    font-size: 14px;
    line-height: 22px;
  }
}
.confirmation-modal .cancel-button {
  margin-right: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.full-screen-loader {
  position: absolute;
  z-index: 2;
  background: #EFF5FB;
}
.full-screen-loader.dim-background {
  opacity: 0.85;
}
.progress-bar .step {
  width: calc(2*45px + 50px);
  position: relative;
}
.progress-bar .step .description {
  margin-top: 3px;
  font-size: 16px;
  line-height: 25px;
}
.progress-bar .step .line {
  position: absolute;
  top: 24px;
  left: calc(45px + 50px);
  width: calc(2*45px);
  border: 0.5px solid #DFDFDF;
}
.progress-bar .step .progress-line {
  position: absolute;
  top: 23px;
  left: calc(45px + 50px);
}
.progress-bar .oval {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid #DFDFDF;
  font-size: 16px;
  font-weight: bold;
}
.progress-bar .oval.busy {
  background-color: #4EB064;
  border: none;
  color: #ffffff;
}
.progress-bar .oval.can-click:hover {
  cursor: pointer;
  opacity: 0.8;
}

.mobile-progress-bar-container {
  height: 10px;
  width: 100%;
  background-color: #DFDFDF;
}
.mobile-progress-bar-container .left-progress {
  background-color: #4EB064;
  height: 100%;
}
.icon-button {
  padding: 0px;
  background: transparent;
  border: none;
}
.icon-button:disabled {
  cursor: not-allowed;
}
.icon-button:not(:disabled) {
  cursor: pointer;
}
.icon-button:focus {
  outline: none;
}
.icon-button.gray {
  color: #DFDFDF;
}
.icon-button.large {
  font-size: 18px;
}
.icon-button.very-large {
  font-size: 24px;
}
.icon-button.success {
  color: #4EB064;
}
.icon-button.warning {
  color: #F6B93B;
}
.icon-button.error {
  color: #EB2F06;
}
.search-box input {
  border: none;
  flex-grow: 1;
}
.search-box input:focus {
  outline: none;
}
.search-box.large input {
  font-size: 16px;
  line-height: 16px;
}
.search-box:not(.large) input {
  font-size: 12px;
  line-height: 12px;
}
.search-box .search-button {
  color: #9e9e9e;
  margin-right: 10px;
  margin-left: 10px;
}
.search-box .clear-button {
  color: #9e9e9e;
  margin-right: 10px;
}
.dd-custom .search-box-container {
  background: #ffffff;
}
.dd-custom .dd-search-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dd-custom .dd-trigger {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
}
.dd-custom .dd-trigger:not(.is-open) {
  border: 1px solid #EDEDED;
}
.dd-custom .dd-trigger.is-open {
  border-top: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
}
.dd-custom .dd-trigger input {
  padding: 10px;
  border: none;
  flex-grow: 1;
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger input:focus {
  outline: none;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger input {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text {
  flex-grow: 1;
  color: #9e9e9e;
}
.dd-custom .dd-trigger .label-text:not(.small) {
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger .label-text:not(.small) {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text.small {
  padding: 10px;
}
.dd-custom .dd-trigger .ddt-icon {
  color: #9e9e9e;
  padding: 10px 10px 10px 0px;
}
.dd-custom .dd-trigger.selected:not(.format-selected) .label-text {
  color: #2E2D2E;
}
.dd-custom .dd-trigger.format-selected {
  background-color: #DE192B;
}
.dd-custom .dd-trigger.format-selected .label-text {
  color: #ffffff;
}
.dd-custom .dd-trigger.format-selected .ddt-icon {
  color: #ffffff;
}
.dd-custom .dd-trigger .add-button {
  margin-left: 10px;
}
.dd-custom .menu.transition.visible {
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0px;
  box-shadow: none;
}

.simple-list-item {
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 8px 10px;
  color: #2E2D2E;
  cursor: pointer;
}
.simple-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.simple-list-item.selected {
  color: #ffffff;
  background: #DE192B;
}
.header-container {
  padding: 40px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #ffffff;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .header-container {
    padding: 0px;
  }
}
.header-container .left {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}
.header-container .right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}
.header-container .cancel-button {
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .header-container .cancel-button, .header-container .language-button {
    color: #626262;
  }
}
.header-container .mobile-cancel-button {
  color: #626262;
}
.header-container .saved-as-draft {
  padding: 7px 15px 7px 15px;
  border-radius: 100px;
  color: white;
  background-color: #4EB064;
  margin-right: 10px;
}
.header-container .saved-as-draft .status-text {
  margin-left: 5px;
  line-height: 21px;
}

.sl-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.field-group {
  display: flex;
  flex-direction: column;
}
.field-group:not(.no-margin) {
  margin-bottom: 20px;
}
.field-group label {
  line-height: 18px;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .field-group label {
    font-size: 14px;
    line-height: 18px;
  }
}
.field-group input::-webkit-input-placeholder {
  font-size: 14px;
}
.field-group input::placeholder {
  font-size: 14px;
}
.field-group input,
.field-group select {
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
  font-size: 18px;
  line-height: 18px;
  padding: 11.5px 8.5px;
  color: #2E2D2E;
}
@media screen and (max-width: 767px) {
  .field-group input,
.field-group select {
    font-size: 16px;
    line-height: 16px;
  }
}
.field-group .error-message {
  font-size: 14px;
  color: #EB2F06;
  padding-top: 5px;
}
.field-group .label-container {
  margin-bottom: 3px;
}
.field-group .label-container .optional-tag {
  color: #9e9e9e;
  font-size: 14px;
}
.field-date-input .ui.input {
  width: 100%;
}
.time-list-items-container {
  flex-wrap: wrap;
  padding: 10px;
}
.time-list-items-container .time-list-item {
  width: 25%;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
}
.time-list-items-container .time-list-item.selected .time-list-item-inner {
  background-color: #DE192B;
  border-radius: 2px;
  color: #FFFFFF;
}
.time-list-items-container .time-list-item:hover .time-list-item-inner {
  background-color: #DE192B;
  border-radius: 2px;
  opacity: 0.8;
  color: #FFFFFF;
}
.select-time-with-specifier .time-picker {
  flex-grow: 1;
  margin-left: 10px;
}
.valid-green-icon {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background-color: #4EB064;
  border: none;
  color: #ffffff;
}
.dd-custom .search-box-container {
  background: #ffffff;
}
.dd-custom .dd-search-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dd-custom .dd-trigger {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
}
.dd-custom .dd-trigger:not(.is-open) {
  border: 1px solid #EDEDED;
}
.dd-custom .dd-trigger.is-open {
  border-top: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
}
.dd-custom .dd-trigger input {
  padding: 10px;
  border: none;
  flex-grow: 1;
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger input:focus {
  outline: none;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger input {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text {
  flex-grow: 1;
  color: #9e9e9e;
}
.dd-custom .dd-trigger .label-text:not(.small) {
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger .label-text:not(.small) {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text.small {
  padding: 10px;
}
.dd-custom .dd-trigger .ddt-icon {
  color: #9e9e9e;
  padding: 10px 10px 10px 0px;
}
.dd-custom .dd-trigger.selected:not(.format-selected) .label-text {
  color: #2E2D2E;
}
.dd-custom .dd-trigger.format-selected {
  background-color: #DE192B;
}
.dd-custom .dd-trigger.format-selected .label-text {
  color: #ffffff;
}
.dd-custom .dd-trigger.format-selected .ddt-icon {
  color: #ffffff;
}
.dd-custom .dd-trigger .add-button {
  margin-left: 10px;
}
.dd-custom .menu.transition.visible {
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0px;
  box-shadow: none;
}

.simple-list-item {
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 8px 10px;
  color: #2E2D2E;
  cursor: pointer;
}
.simple-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.simple-list-item.selected {
  color: #ffffff;
  background: #DE192B;
}

.valid-icon {
  margin-right: 10px;
}

.warning-icon {
  margin-right: 10px;
  color: #F6B93B;
}
.warning-message {
  font-size: 14px;
  padding-top: 5px;
  color: #F6B93B;
}
.text-area-field {
  width: 100%;
  resize: none;
  height: 150px;
  padding: 14.5px 8.5px;
  font-size: 18px;
  line-height: 24px;
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
}
.add-to-addressbook, .use-default-load-address-title {
  font-size: 16px;
}

.use-default-load-address-container {
  margin-bottom: 20px;
}
.stop-view {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .stop-view {
    font-size: 14px;
    line-height: 21px;
  }
}
.stop-view .stop-button {
  text-align: right;
}
.stop-view .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.stop-view .label:not(.without-margin) {
  margin-top: 20px;
}
.stop-top-margin {
  margin-top: 20px;
}
.max-stops-container {
  margin-top: 30px;
}
.max-stops-container .max-stops-icon {
  font-size: 20px;
  margin-top: 4px;
  color: #9e9e9e;
  margin-right: 10px;
}
.max-stops-container .max-stops {
  font-size: 12px;
  line-height: 18px;
}
.header-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ui.modal.default-modal .content .blank-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blank-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container {
    margin-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blank-container .blank-container-buttons {
  margin-top: 40px;
}
.blank-container .blank-container-buttons .previous-button {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .blank-container .blank-container-buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid #EDEDED;
  }
  .blank-container .blank-container-buttons .df-row-ac {
    width: 100%;
    justify-content: space-between;
  }
  .blank-container .blank-container-buttons .df-row-ac .primary-button {
    width: auto;
  }
}

.blank-mobile-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  border-top: 1px solid #EDEDED;
}

.blank-container .main-select-container {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container .main-select-container {
    justify-content: space-between;
  }
}
.blank-container .main-select {
  color: #9e9e9e;
  font-size: 18px;
  line-height: 36px;
  border-bottom: 2px solid #9e9e9e;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .blank-container .main-select {
    font-size: 16px;
    line-height: 32px;
  }
}
.blank-container .main-select.has-selected {
  color: #2E2D2E;
  font-weight: 600;
  border-bottom: 2px solid #2E2D2E;
}
.server-error-text {
  margin-top: 20px;
  white-space: pre-line;
}
.dd-container .dd-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
.dd-container .dd-label-file-number {
  font-size: 16px;
  line-height: 25px;
  color: #626262;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-label-file-number {
    font-size: 14px;
    line-height: 21px;
  }
}
.dd-container .dd-file-number {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 14px;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-file-number {
    font-size: 16px;
    line-height: 25px;
  }
}
.dd-container .cancel-button {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .dd-container .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.header-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ui.modal.default-modal .content .blank-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blank-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container {
    margin-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blank-container .blank-container-buttons {
  margin-top: 40px;
}
.blank-container .blank-container-buttons .previous-button {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .blank-container .blank-container-buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid #EDEDED;
  }
  .blank-container .blank-container-buttons .df-row-ac {
    width: 100%;
    justify-content: space-between;
  }
  .blank-container .blank-container-buttons .df-row-ac .primary-button {
    width: auto;
  }
}

.blank-mobile-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  border-top: 1px solid #EDEDED;
}

.calculated-container {
  font-size: 16px;
  line-height: 25px;
}
.calculated-container .single-price-calc {
  margin-right: 20px;
}
.calculated-container .c-summ {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.calculated-container .add-cost-desc {
  margin-left: 20px;
}
.calculated-container .gen-cond-container {
  margin-top: 30px;
}
.calculated-container .gen-cond-container .gen-cond-icon {
  font-size: 20px;
  margin-top: 4px;
  color: #EB2F06;
  margin-right: 10px;
}
.calculated-container .gen-cond-container .gen-cond {
  font-size: 12px;
  line-height: 18px;
}
.calculated-container .calc-item {
  margin-top: 10px;
}
.calculated-container .total {
  font-size: 18px;
  font-weight: 600;
}
.calculated-container .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.calculated-container .label:not(.without-margin) {
  margin-top: 20px;
}
.calculated-container .info {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .calculated-container .info {
    font-size: 14px;
    line-height: 21px;
  }
}
.calculated-container .calc-stops-view {
  margin-top: 20px;
}

