@import '../../../styles/variables';

.primary-button {
    border-radius: 4px;
    box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
    text-align: center;
    text-decoration: none;
    border: 1px solid $primary-color;
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;
    white-space: nowrap;

    .loader-wrapper {
        position: absolute;
        width: 100%;
    }

    .loader-children {
        visibility: hidden;
    }

    &.stretch {
        width: 100%;
    }

    &:not(.small) {
        font-size: 20px;
        line-height: 30px;
        padding: 15px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &.small {
        font-size: 14px;
        line-height: 21px;
        padding: 5px;
        font-weight: 400;
    }

    &:not(.outline) {
        background-color: $primary-color;
        color: $white;
    }

    &.outline {
        color: $neutral-1-color;
        border: $default-border;
        background: transparent;
    }

    &:hover:not(.drop-down disabled) {
        opacity: 0.7;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    &:focus {
        outline: none;
    }

    &.rectangle-padding {
        padding: 10px 30px;
    }
}